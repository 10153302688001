import accesexport from "@content/tutorial/images/acces-export.png";
import nouvelexport from "@content/tutorial/images/nouvel-export.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatExport() {
  return (
    <PageLayout title="Export Study" roles={["admin"]}>
      <Section id="accessing-the-export" title="Accessing the Export">
        <p>
          Once all your patients' data is collected, you can export the data to
          visualize, process, or analyze it. To do this, go to the{" "}
          <b>Analysis</b> tab and then <b>Exports</b>.
        </p>
        <img src={accesexport} alt="Exports Tab" className="centered" />
        <h2 id="exporting-data">Exporting Data</h2>
        <p>
          Click on <b>New Export</b> to request an export; it will be processed
          within 5 minutes of your request.
        </p>
        <img src={nouvelexport} alt="New Export" className="centered" />
        <Note type="note">
          <p>
            If the export request does not appear immediately, click the "
            <b>Reload</b>" button at the bottom of the list.
          </p>
        </Note>
      </Section>
    </PageLayout>
  );
}
